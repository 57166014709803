import React from 'react';
import { observer, inject } from 'mobx-react';
// import ReactDOM from 'react-dom';
// import style from './style.module.css';
// import SideMenu from './SideMenu';
// import ItemList from './ItemList';
// import Basket from './Basket';
import AppBodyLayout from '../../../layouts/AppBodyLayout';


@inject(store => {
    const { myShopApiStore } = store;
    return { myShopApiStore };
})

@observer
class ShopMenu extends React.Component {
    render() {
        const { isCartItemUpdating, isPackItemUpdating } = this.props.myShopApiStore;
        return <AppBodyLayout basketIsOpen={this.props.basketIsOpen}
                    isCartItemUpdating={isCartItemUpdating}
                    isPackItemUpdating={isPackItemUpdating}
                    closeBasket={this.props.closeBasket}
                    toggleBasket={this.props.toggleBasket}
                    fastToggleBasket={this.props.fastToggleBasket}
                />
    }
}

export default ShopMenu;