import React from 'react';
import { observer, inject } from 'mobx-react';
// import ReactDOM from 'react-dom';
import searchBg from '../../../assets/search.png';
import style from './style.module.css';
import TopMenuLayout from 'Components/layouts/TopMenuLayout';

@inject(store => {
    const { ifaceMngr, myShopApiStore } = store;
    return { ifaceMngr, myShopApiStore };
})

@observer
class TopMenu extends React.Component {
    render() {
        const { closeStore, userToken, userParams, shopsData, shop, userId, thisCart, isPackItemUpdating, isCartItemUpdating, setSearchValue, shopData, my_env, __mobx_sync_versions__} = this.props.myShopApiStore;
        return (
        <div className={style.AppTopMenu}>
            <TopMenuLayout activeTopMenu={this.props.ifaceMngr.activeTopMenu}
                                setActiveTopMenu={this.props.ifaceMngr.setActiveTopMenu}
                                closeStore={closeStore}
                                userParams={userParams}
                                userToken={userToken}
                                shopsData={shopsData}
                                userId={userId}
                                isPackItemUpdating={isPackItemUpdating}
                                isCartItemUpdating={isCartItemUpdating}
                                thisCart={thisCart}
                                shop={shop}
                                setSearchValue={setSearchValue}
                                basketIsOpen={this.props.basketIsOpen}
                                closeBasket={this.props.closeBasket} 
                                toggleBasket={this.props.toggleBasket}
                                fastToggleBasket={this.props.fastToggleBasket}
                                shopData={shopData}
                                my_env={my_env}
                                __mobx_sync_versions__={__mobx_sync_versions__}
                                dir={this.props.ifaceMngr.languageMap[this.props.ifaceMngr.i18nextLng].dir}
                                />
        </div>
        )
    }
}

export default TopMenu;
