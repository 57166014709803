import React from 'react';
import { observer, inject } from 'mobx-react';
import style from './style.module.css';
import Modal from 'react-modal';

@inject(store => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})
@observer
class WelcomeShopsImageModal extends React.Component {
    constructor(props) {
        super(props);
        this.modalStyle = {
            content: {
                overflow: "hidden",
                background: 'none',
                maxWidth: '1050px',
                maxHeight: '640px',
                marginLeft: 'auto',
                marginRight: 'auto',
                border: 'none',
                inset: '0px',
                marginTop: '140px',
                marginBottom: '140px',
                paddingLeft: '50px',
                paddingRight: '50px'
            },
            overlay: {
                backgroundColor: "rgb(0, 0, 0, 0.65)",
                zIndex: 100
            }
        }
    }

    goMenu = () => {
        const { setActiveTopMenu } = this.props.ifaceMngr;
        const { closeModal } = this.props;
        
        setActiveTopMenu("ShopMenu");
        closeModal();
    }
    render() {
        const {modalIsOpen } = this.props;
        const { shopsData } = this.props.myShopApiStore;
        return (
            <Modal style={this.modalStyle} isOpen={modalIsOpen} onRequestClose={this.goMenu} ariaHideApp={false} shouldCloseOnOverlayClick={shopsData.applicationBlocked == '0'} shouldCloseOnEsc={shopsData.applicationBlocked == '0'}>
                {
                    shopsData.applicationBlocked == '1' ?
                        ''
                    :
                        <div className={style.cancleBtn} onClick={this.props.closeModal}>✖</div>
                }
                <div className={style.welcomeImageDiv}>
                    <img className={style.welcomeImage} src={this.props.isMobile ? shopsData.welcomeImageSite/*shopsData.welcomeImage*/ : shopsData.welcomeImageSite} alt='תמונה'/>
                </div>
            </Modal>
        )
    }
}

export default WelcomeShopsImageModal;