import React from 'react';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import style from './style.module.css';
import Modal from 'react-modal';

@inject(store => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})

@observer
class ApproveOrderModal extends React.Component {
    constructor(props) {
        super(props);
        this.modalStyle = {
            content : {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '20px',
                maxWidth: '500px',
                // height: '290px',
                overflow: 'hidden'
                // maxHeight: '300px'
                
            },
            overlay: {
                backgroundColor: "rgb(0, 0, 0, 0.65)",
                zIndex: 10
            }
        }
    }

    handleApproveOrder = async () => {
        const { checkApproveOrderResp, getOrderHistoryResp, resetOrdersHistory, resetHistoryPage, updateOneOrdersHistory } = this.props.myShopApiStore;

        //resetOrdersHistory();
        //resetHistoryPage();

        const resp = await checkApproveOrderResp(this.props.orderId);
        if(resp) {
            //resetOrdersHistory();
            //resetHistoryPage();
            //await getOrderHistoryResp()
            updateOneOrdersHistory(this.props.okey, resp.data.data)
            
            this.props.closeModal()
        }
    }

    render() {
        const { modalIsOpen, closeModal, t } = this.props;
        const { shop, shopData } = this.props.myShopApiStore;

        return (
            <Modal style={this.modalStyle} isOpen={modalIsOpen} onRequestClose={closeModal}>
                <div className={style.Container}>
                    <div className={style.CancleXBtn}>
                        <span onClick={closeModal}>✕</span>
                    </div>
                    <div className={style.TextContnet}>
                        <div className={style.Title}>
                            <span>{t('Approvement')}</span>
                        </div>
                        <div className={style.RowsText}>
                            <br/>
                            <span>{t('toApprove')}</span>
                            <br/>
                            <span>{t('toApprovePayAttention')}</span>
                            <br/>
                        </div>
                    </div>
                    <div className={style.Btns}>
                        <div className={style.BackBtn}>
                            <span onClick={this.handleApproveOrder}>{t('Approvements')}</span>
                        </div>
                        <div className={style.ContinueBtn}>
                            <span onClick={closeModal}>{t('back')}</span>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default withTranslation()(ApproveOrderModal);