import React from "react";
import style from "./style.module.css";
import { useTranslation } from "react-i18next"

function CategoryTime (props) {
    const { category } = props;
    const { t } = useTranslation();
    return (
        <div className={style.Container} >
                {t('CategoryProductsAvailableFromTo', {category})}
        </div>
    )
}

export default CategoryTime