import React from 'react';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
// import ReactDOM from 'react-dom';
import style from './style.module.css';
import Item from './Item';
// import CurierLocation from '../CurierLocation';

@inject(store => {
    const { myShopApiStore } = store;
    return { myShopApiStore };
})

@observer
class Basket extends React.Component {
    
    handleAddToCartClick = () => {
        const { basketHistory, addToCart, closeCartItemForm} = this.props.myShopApiStore;

        if(basketHistory){
            basketHistory.products.forEach(product => {
                addToCart(product.shopProduct, product.unitType);
                closeCartItemForm();
            }); 
        }
    }

    render() {
        const { basketHistory } = this.props.myShopApiStore;
        const { t } = this.props;
        return (
            <div className={style.Container}>
                <div className={style.CancelXBtn}>
                    <span onClick={this.props.hideBasket}>✕</span>
                </div>
                <div className={style.OrderNumber} >{` ${t('_order number')} #${basketHistory?.id}`}</div>
                <div className={style.List}>
                    {
                        basketHistory?.products?.map((item, index) => 
                            <Item item={item} index={index} key={`p_${index}_${item.id}`} />
                        )
                    }
                </div>
            </div>
        )
    }
}

export default withTranslation()(Basket);