import React from 'react';
import { observer, inject } from 'mobx-react';
import Swing from 'UI/Swing';

// import ReactDOM from 'react-dom';
import style from './style.module.css';
import MenuItem from './MenuItem';

@inject(store => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})

@observer
class SideMenu extends React.Component {
    
    render() {
        const { categories } = this.props.myShopApiStore;
        const { isMobile, isRTL } = this.props.ifaceMngr;

        if(!categories || categories.length === 1){
            return null;
        }

        const brdRadius = isMobile ? 0 : getComputedStyle(document.querySelector(':root')).getPropertyValue('--brdRadius');
        const borderRadius = !isRTL ? `0 ${brdRadius} ${brdRadius} 0` : `${brdRadius} 0 0 ${brdRadius}`;
        this.props.myShopApiStore.setMaxSideMenuIdx(categories.length - 1);
        return (
            <div className={style.Container} style={{ borderRadius }}>
                <Swing isHorisontal={isMobile} >
                    {listRef => <div className={style.ItemList} ref={listRef}>
                        {
                            categories.map((item, index) => 
                                    <MenuItem item={item} index={index} key={`p_${index}`}/>
                            )
                        }
                    </div>}
                </Swing>
            </div>
        )
    }
}

export default SideMenu;