import React from 'react';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import style from './style.module.css';
import Modal from 'react-modal';
import sad from '../../../../../../assets/sad.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

@inject(store => {
    const { myShopApiStore } = store;
    return { myShopApiStore };
})

@observer
class ChangeOrderTypeModal extends React.Component {
    render() {
        const { modalIsOpen, closeModal, changeOrderType, toType, t } = this.props;
        const modalStyle = {
            content : {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%,-50%)',
                padding: 0,
                borderRadius: '20px',
                // width: '70%',

            },
            overlay: {
                backgroundColor: "rgb(0, 0, 0, 0.75)",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 10,

            },
        }

        

        Modal.setAppElement('body');

        return(
            <Modal onRequestClose={closeModal}
                    isOpen={modalIsOpen}
                    style={modalStyle}   
                    contentLabel={t('Are you going to change order type')}>

                <div className={style.ModalContainer}>
                    <div className={style.CloseBtn}>
                        <span onClick={closeModal}>✕</span>
                    </div>
                    <div className={style.Body}>
                        <LazyLoadImage effect='blur' src={sad} width='80px'/>
                        <div className={style.ModalTitle}>
                            <span>{t('Are you going to change order type')}</span>
                        </div>
                        <div className={style.RowText}>
                            <span>{t('your order will be empty')}</span>
                        </div>
                        <button type="text" onClick={()=> {
                            changeOrderType(toType); 
                            closeModal(); 
                        }}>
                            {t('Reset basket')}           
                        </button>
                        <button type="text" onClick={closeModal}>
                            {t('Leave order')}
                        </button>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default withTranslation()(ChangeOrderTypeModal);