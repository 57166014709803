import React from 'react';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
// import ReactDOM from 'react-dom';
import style from './style.module.css';
import { timeView } from 'utils/utils';

@inject(store => {
    const { myShopApiStore } = store;
    return { myShopApiStore };
})

@observer
class WorkTime extends React.Component {
    render() {
        const { t } = this.props;
        const { timeFormat } = this.props.myShopApiStore.shopData.country ?? {};

        return (
            <div className={style.container}>
                <div className={style.TopDiv}>
                    {
                        this.props.items.map((item, idx) => {
                            return (
                                <div className={style.day} key={`time_${idx}`}>
                                    <div className={style.dayName}>{t('day')} {t(item.weekday)}</div>
                                    <div className={style.dayHours}>{timeView(item.from, timeFormat)}-{timeView(item.to, timeFormat)}</div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}

export default withTranslation()(WorkTime);