import React, { Component } from "react";
import style from "./style.module.css";
import { observer, inject } from "mobx-react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { withTranslation } from "react-i18next";
import 'react-lazy-load-image-component/src/effects/blur.css';
import bigappslogo from '../../../assets/bigappslogo.png'
import apple from '../../../assets/apple.png'
import android from '../../../assets/android.png'


const menuBtns = [ 'PPolicy', 'RetPolicy', 'Terms', 'Contacts',  'Accessibility'];

const menuBtnsFalafelZikhron = [ 'PPolicy', 'RetPolicy', 'Terms', 'WorkingMode', 'Accessibility' ];

@inject((store) => {
  const { ifaceMngr, myShopApiStore } = store;
  return { ifaceMngr, myShopApiStore };
})
@observer
class Footer extends Component {
  constructor(props) {
    super(props);
  
    this.state = {
      modalIsOpen: true, //welcome modal
      carouselModalIsOpen: true,
      isMobile: window.innerWidth <= 850,
      basketIsOpen: false,
    };
  }

  render() {

    const { setActiveTopMenu, activeTopMenu, isRTL } = this.props.ifaceMngr
    const { userId, shop, shopData, getAccessibility, setSearchValue, my_env, __mobx_sync_versions__, storeOpened } = this.props.myShopApiStore;
    const { t } = this.props;
    return (
      <div className={style.Container} style={{...(storeOpened ? {} :{justifyContent: 'center'})}}>
        <div className={style.VersionDiv}>
          v{__mobx_sync_versions__.__mobx_sync_this__}
        </div>
        { storeOpened && 
          <div className={style.MenuBtns}>
            {
              ( my_env.url.indexOf("falafelzikhron") >= 0 ? menuBtnsFalafelZikhron : menuBtns)
                  .filter(item => !(
                    (item === 'History' && userId === null) 
                    || (item === 'Accessibility' && !shopData?.accessibilityMessage)
                    ))
                  .map((item, index) => 
                    <span key={index} onClick={() => setSearchValue('', true)}>
                                <div className={activeTopMenu === item ? `${style.menubtn} ${style.menubtnActive}` : style.menubtn} 
                                    onClick={() => setActiveTopMenu(item)} >{t(item)}</div>
                      </span>
                    )
            }
          </div>}
        <div className={style.BigappsLogo} 
            onClick={()=> window.open(
                !shopData.country || shopData.country.name === 'Israel' 
                  ? "https://my-shop.co.il/" : "https://myshoptechnologies.com/",  
                "_blank"
              )}>
            <LazyLoadImage src={bigappslogo} alt="my-shop" />
        </div>
        <div className={style.MobileBtns}>
          { storeOpened && <>{ !!shop?.linkGoogle &&
                <LazyLoadImage src={android} alt="android" 
                  onClick={()=> window.open(shop.linkGoogle, "_blank")}
                />}
              {!!shop?.linkApple &&
                <LazyLoadImage src={apple} alt="apple" width="100%"
                  onClick={()=> window.open(shop.linkApple, "_blank")}
                />}
          </>}
        </div>
      </div>
    )
  }
}
export default withTranslation()(Footer);