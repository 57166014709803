import React from 'react';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
// import ReactDOM from 'react-dom';
import style from './style.module.css';
import { BsBasket3Fill } from "react-icons/bs";


@inject(store => {
    const { ifaceMngr, myShopApiStore } = store;
    return { ifaceMngr, myShopApiStore };
})

@observer
class Empty extends React.Component {
    
    componentDidMount = () => {
        this.props.hideBasket();
    }

    render() {
        const { shop } = this.props.myShopApiStore;
        const { t } = this.props;

        return (
            <div className={style.container}>
                <div id="" className={style.TopDiv}>
                    <div className={style.Desc}>{t('You have no previous orders')}</div>
                    <button 
                        onClick={() => this.props.ifaceMngr.setActiveTopMenu('ShopMenu')} 
                    >
                        <div>{t("Click on basket and let's get started")}</div>
                        <BsBasket3Fill />
                        {/* <img className={style.imgBasket} src={imgBasket} alt={t("Your basket is empty")}/> */}
                    </button>
                </div>
            </div>
        )
    }
}

export default withTranslation()(Empty);