// import moment from "moment";

// /**
//  * Function returning the build date(as per provided epoch)
//  * @param epoch Time in milliseconds
//  */
// export const getBuildDate = (epoch) => {
//   console.log('IN getBuildDate!')
//   const buildDate = moment(epoch).format("DD-MM-YYYY HH:MM");
//   return buildDate;
// };

export const timeView = (time, format='H:i') => {
  if (!time || !format) return '';
  const timeObj = new Date(0,0,0, ...time.split(':'));
  const hour = timeObj.getHours();
  const minute = timeObj.getMinutes();
  const period = hour >= 12 ? 'PM' : 'AM';
  return format
      .replace('H', String(hour).padStart(2, '0'))
      .replace('h', String(hour % 12 || 12).padStart(2, '0'))
      .replace('i', String(minute).padStart(2, '0'))
      .replace(':s', '')
      .replace('a', period.toLowerCase())
      .replace('A', period);
}

export const timeEView = (time, format='H:i') => {
  if (!time || !format) return '';
  const timeObj = new Date(time * 1000);
  const hour = timeObj.getHours();
  const minute = timeObj.getMinutes();
  const period = hour >= 12 ? 'PM' : 'AM';
  return format
      .replace('H', String(hour).padStart(2, '0'))
      .replace('h', String(hour % 12 || 12).padStart(2, '0'))
      .replace('i', String(minute).padStart(2, '0'))
      .replace(':s', '')
      .replace('a', period.toLowerCase())
      .replace('A', period);
}

export const dateView = (date, format = 'd.m.Y') => {
  const dateObj = (date instanceof Date) ? date : (new Date(date * 1000));
  const day = dateObj.getDate();
  const month = dateObj.getMonth() + 1;
  const year = dateObj.getFullYear();
  return format
      .replace('d', String(day).padStart(2, '0'))
      .replace('m', String(month).padStart(2, '0'))
      .replace('Y', year);
}

export const urlify = (text) => {
  const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)/g;
  
  return text.replace(urlRegex, function(url) {
    const hyperlink = (!url.match('^https?://') ? 'https://' : '') + url;
    return `<a href="${hyperlink}" rel="noopener" noreferrer target="_blank">${url}</a>`
  })  
}

export const translate = (obj, lng) => { 
  if (!obj) return;
  obj.i18n && Object.keys(obj.i18n).forEach(k => obj.i18n[k][lng] && (obj[k] = obj.i18n[k][lng]));
  obj.product && translate(obj.product, lng)
  obj.levels?.forEach(l => translate(l, lng))
  obj.products?.forEach(p => translate(p, lng))
  obj.shopBaseToppings?.forEach(({baseTopping}) => translate(baseTopping, lng)); 
  obj.shopToppings?.forEach(({topping}) => translate(topping, lng)); 
  obj.shopProductOptions?.forEach(({productOption})=> translate(productOption, lng));
}